<template>
  <div class="w-100">
    <h5 class="mb-0">{{ $t('teamFormation') }}</h5>
    <hr>
    <form @submit.prevent="save">
      <b-table striped bordered hover :items="players" :fields="columns">
        <div slot="order" slot-scope="row" class="text-right">{{ row.index + 1 }}</div>
        <div slot="show" slot-scope="row">
          <b-form-checkbox v-model="playersSelecteds" name="playersSelecteds[]" :value="row.item.player_id" switch></b-form-checkbox>
        </div>
      </b-table>
      <div class="w-100 text-center">
        <b-button type="submit" variant="primary" size="lg">{{ $t('save') }}</b-button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      players: [],
      playersSelecteds: [],
      columns: [
        { key: 'order', label: '' },
        { key: 'nick', label: this.$t('player'), sortable: true },
        { key: 'position', label: this.$t('position'), sortable: true },
        { key: 'show', label: this.$t('show'), sortable: false }
      ],
      teamId: this.$route.params.teamId
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'team',
      'user'
    ])
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const params = {
        tournament: this.$route.query.tournament
      }
      const path = `auth/${this.lang}/${this.console}/teams/${this.teamId}/players-formation`
      this.$axios.get(path, { params }).then(response => {
        const positions = window.positions
        this.players = response.data.data.map(p => {
          const position = p.position
          p.position = positions.filter(pos => {
            return pos.value === position
          }).reduce(pos => {
            return pos
          }).text
          return p
        })
        this.players.map(pla => {
          if (pla.show) {
            this.playersSelecteds.push(pla.player_id)
          }
        })
      })
    },
    save () {
      const players = []
      const users = []
      this.players.map(p => {
        if (this.playersSelecteds.indexOf(p.player_id) !== -1) {
          players.push(p.player_id)
          users.push(p.user_id)
        }
      })
      const payload = {
        tournament_id: this.$route.query.tournament,
        players,
        users
      }
      const path = `auth/${this.lang}/${this.console}/teams/${this.teamId}/players-formation`
      this.$axios.post(path, payload).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    }
  }
}
</script>
